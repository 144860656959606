<template>
  <div>
    <h3 class="mb-5 text-secondary">{{ $t("page.domiUser.title") }}</h3>
    <domi-filters class="mb-5" />
    <b-row class="no-gutters justify-content-end mb-3">
      <b-col class="col-auto">
        <b-button size="md" variant="secondary" @click="openCreateModal">
          {{ $t("button.createUser") }}
        </b-button>
      </b-col>
    </b-row>
    <user-table
      :users="formatedUsers"
      :fields="fields"
      :actions="actions"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    />

    <create-domi-user :state.sync="createModalState" />
  </div>
</template>

<script>
import userMixin from "./../mixins/userMixin";

import UserTable from "./../components/User/UserTable";
import DomiFilters from "./../components/Domi/DomiFilters";
import CreateDomiUser from "./../components/Domi/CreateDomiUser";
export default {
  components: {
    UserTable,
    DomiFilters,
    CreateDomiUser
  },
  mixins: [userMixin],
  data() {
    return {
      createModalState: false,
      fields: [
        { key: "fullName", label: "nameAndSurname", sort: true },
        { key: "email", label: "email", sort: true },
        { key: "telephone", label: "telephone", sort: false },
        { key: "status", label: "status", sort: false },
        { key: "actions", label: "" }
      ],
      actions: []
    };
  },
  computed: {
    domiUsers() {
      return this.$store.getters["domi/getDomiUsers"];
    },
    formatedUsers() {
      return this.domiUsers.map(item => {
        return {
          id: item._id,
          photo: this.$_getUserProfilePhoto(item.profile),
          fullName: this.$_getUserFullName(item.profile),
          email: item.email,
          telephone: this.$_getUserTelephone(item),
          status: item.status
        };
      });
    },
    sortBy: {
      get() {
        return this.$store.getters["domi/getSortBy"];
      },
      set(value) {
        this.$store.commit("domi/SET_SORT_BY", value);
      }
    },
    sortDesc: {
      get() {
        return this.$store.getters["domi/getSortDesc"];
      },
      set(value) {
        this.$store.commit("domi/SET_SORT_DESC", value);
      }
    }
  },
  methods: {
    openCreateModal() {
      this.createModalState = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
